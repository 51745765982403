body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #B8B8D1;
}

@font-face {
  font-family: 'DMSans';
  src: local('DMSans-Regular'), url(./assets/fonts/DMSans-Regular.ttf) format('woff');
}

@font-face {
  font-family: 'DMSerifDisplay';
  src: local('DMSerifDisplay-Regular'), url(./assets/fonts/DMSerifDisplay-Regular.ttf) format('woff');
}

@font-face {
  font-family: 'DMSerifDisplay-Italic';
  src: local('DMSerifDisplay-Italic'), url(./assets/fonts/DMSerifDisplay-Italic.ttf) format('woff');
}

@font-face {
  font-family: 'Harmond';
  src: local('Harmond-SemBdItaCond'), url(./assets/fonts/Harmond-SemBdItaCond.otf) format('woff');
}

@font-face {
  font-family: 'Harmond-ExtraBold';
  src: local('Harmond-ExtraBoldExpanded'), url(./assets/fonts/Harmond-ExtraBoldExpanded.otf) format('woff');
}

@font-face {
  font-family: 'Mangabey';
  src: local('Mangabey'), url(./assets/fonts/Mangabey.ttf) format('woff');
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'DMSans';
  line-height: 150%;
  scroll-margin-top:calc(4.5vw - 1px);
}

.title, .subtitle, .p-subtitle, .text {
  padding: 0 0 5px 0;
}

.text {
  padding-top: 5px;
}

.title {
  font-family: "DMSerifDisplay";
}

.p-subtitle {
  font-family: "DMSerifDisplay-Italic";
}

.subtitle, .text {
  font-family: "DMSans";
}

.fancy-title{
  font-family: "Mangabey";
}

.background-wow {
  background-color: #F4ed71;
}

.background-blue {
  background-color: #2b2bad;
  color: white;
  flex-direction: row-reverse;
}

.background-light {
  background-color: #B8B8D1;
}

.background-peach {
  background-color: #fdab72;
}

.background-dark {
  background-color: #5B5F97;
  color: white;
}

.paragraph .title {
  font-size: 2.4vw;
}

.paragraph .p-subtitle {
  font-size: 2vw;
}

.paragraph .text {
  font-size: 1.5vw;
}

.parallax {
  min-height: 60vh;
  /* background-attachment: fixed; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#parallax-1:hover {
  opacity: 70%;
}

#parallax-1 {
  background-image: url("/src/assets/images/lucky1.png");
  opacity: 60%;
  -webkit-transition: opacity 200ms ease-out;
  -ms-transition: opacity 200ms ease-out;
  transition: opacity 200ms ease-out;
}

.p-text-only {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 3% 20%;
}

.p-container {
  height: max-content;
}

.p-image-container {
  display: flex;
  justify-content: center;
}

.p-with-image, .p-image-container {
  width: 50%;
  padding: 5% 2.5%;
}

.p-image-container img, .social img {
  width: 90%;
  height: auto;
}

.twoImages img {
  width: 100%;
  height: auto;
}

.twoImages {
  height: max-content;
  display: flex;
  flex-direction: row;
  padding: 2.5% 0 5% 0;
  justify-content: space-evenly;
}

.twoImages div:nth-child(1) {
  width: 35%; 
  height: max-content;
}

.twoImages div:nth-child(2) {
  width: 35%;
  height: max-content;
  margin: 3% 0 0 0;
}

.p-with-image-container {
  display: flex;
  padding: 0 2.5%;
}

header {
  position: fixed;
  display: flex;
  color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.5vw;
  font-size: 1.3vw;
  z-index: 100;
  padding: 0 10vw;
  -webkit-transition: background-color 500ms ease-out;
  -ms-transition: background-color 500ms ease-out;
  transition: background-color 500ms ease-out;
}

.nav {
  width: calc(100% - 20vw);
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  height: 4.5vw;
}

.header-transparent {
  background-color: transparent;
}

.header-logo {
  height: 4.5vw;
}

#menuToggle {
  display: none;
}

.header-dark {
  background-color: #1b1a6a;
}

header span:hover{
  cursor: pointer;
  opacity: 80%;
}

footer {
  height: max-content;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "DMSans";
  padding-bottom: 5vh;
  font-size: 1.4vw;
}

.contact-items {
  display: flex;
  width: 50vw;
  justify-content: center;
}

.contact-icon {
  width: 2.5vw;
  height: auto;
}

.social img:hover {
  opacity: 80%;
}

.social {
  padding: 10px 10px 0 10px;
}

.contact-item {
  padding: 10px 20px;
}

.contact-item, .contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact {
  padding: 5vh 0 0 0;
}

.social {
  width: 5vw;
}

.design-and-code {
  display: flex;
  height: 10%;
  align-items: center;
}

.design-and-code a {
  padding: 0 0 0 10px;
}

hr {
  width: 95vw;
  opacity: 50%;
}

.hero-container {
  background-image: url("/src/assets/images/IMG_8108.jpg.png");
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  width: 100vw;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-container .fancy-title {
  font-size: 8vw;
}

.hero-container .hero-title {
  font-size: 3.5vw;
  font-family: DMSerifDisplay-Italic;
}

.form-container {
  display: flex;
  width: 100;
  align-items: center;
  justify-content: center;
}

.form {
  width: 40%;
  height: max-content;
  padding: 5% 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form input, .form button {
  width: 90%;
}

.form input {
  height: 7vh;
  font-size: 2.5vh;
  padding: 2%;
  margin: 2% 0;
  border: 1px solid transparent;
  border-radius: 15px;
}

.form button {
  height: 8vh;
  font-size: 2.6vh;
  margin: 2% 0;
  border: 1px solid transparent;
  border-radius: 15px;
  background-color: #2b2bff;
  color: white;
  -webkit-transition: background-color 200ms ease-out;
  -ms-transition: background-color 200ms ease-out;
  transition: background-color 200ms ease-out;
}

.form button.disabled {
  background-color: #1b1a6a;
}

.loader {
  border: 4px solid #FFF;
  border-top-color: rgba(0, 0, 0, 0);
  border-left-color: rgba(0, 0, 0, 0);
  width: 20px;
  height: 20px;
  opacity: 0.8;
  border-radius: 50%;
  animation: loadingSpinner 0.7s infinite linear;
  -webkit-animation: loadingSpinner 0.7s infinite linear;
}

.form button:hover {
cursor: pointer;
}

#participate {
  font-size: 4vw;
  text-align: center;
}

#sign-up{
  font-size: 3vw;
  text-align: center;
}

.aida a{
  color: white;
}

.aida a:hover{
  opacity:80%;
}

#current-announcements {
  display: flex;
  font-size: 3vw;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10% 5% 2%;
  flex-direction: column;
}

#current-announcements #participate {
  text-decoration: underline;
  cursor: pointer;
  font-size: 4vw;
  padding: 3% 0;
}

.announcement-container {
  height: 90vh;
  padding: 2.5% 0 5% 0;
  justify-content: center;
  display: flex;
}

.reminder {
  color: #2b2bff;
  width: 90%;
  text-align: left;
}

.footer-logo {
  width: 10%;
}

@media only screen and (max-width: 992px) { 
  * {
    scroll-margin-top:59px;
  }

  #menuToggle {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100vw;
    right: 0;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
  }

  .header-logo {
    position: absolute;
    left: 5%;
    height: 50px;
  }

  header {
    height: 60px;
    padding: 0;
    justify-content: unset;
  }

  .nav {
    display: none;
  }
  
  #menuToggle input
  {
    display: flex;
    width: 32px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    top: -10px;
    right: 5%;
    z-index: 2;
  }
  
  #menuToggle span
  {
    display: flex;
    width: 29px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: #ffffff;
    border-radius: 3px;
    z-index: 1;
    left: calc(100vw - 29px - 5%);
    transform-origin: 5px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
  }
  
  #menuToggle span:first-child
  {
    transform-origin: 0% 0%;
  }
  
  #menuToggle span:nth-last-child(2)
  {
    transform-origin: 0% 100%;
  }
  
  #menuToggle input:checked ~ span
  {
    opacity: 1;
    transform: rotate(45deg) translate(-3px, -1px);
  }
  #menuToggle input:checked ~ span:nth-last-child(3)
  {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }
  
  #menuToggle input:checked ~ span:nth-last-child(2)
  {
    transform: rotate(-45deg) translate(0, -1px);
  }
  
  #menu
  {
    position: absolute;
    width: 100vw;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: -60px 0 0 0px;
    padding: 50px;
    padding-top: 90px;
    background-color: #1b1a6a;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(100%, 0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    list-style-type: none;
  }
  
  #menu li
  {
    padding: 10px 0;
    transition-delay: 2s;
    width: max-content;
    font-size: 3em;
  }
  
  #menuToggle input:checked ~ ul
  {
    transform: none;
  }

  .hero-container .fancy-title {
    font-size: 4em;
  }

  .hero-container .hero-title { 
    font-size: 2em;
  }

  .p-with-image-container {
    flex-direction: column;
    display: flex;
    padding: 0;
  }

  .p-image-container img {
    width: 100%;
  }

  .p-text-only {
    padding: 5% 7.5%;
  }

  .paragraph .title, #participate.title {
    font-size: 1.2em;
  }

  .paragraph .p-subtitle, #sign-up, .form input {
    font-size: 1em;
  }

  .paragraph .text {
    font-size: 0.9em;
  }

  .p-with-image, .p-image-container {
    width: 100%;
  }

  .p-image-container {
    width: 100%;
    padding: 0% 7.5% 10% 7.5%;
  }

  .twoImages {
    flex-direction: column;
    align-items: center;
    padding: 0 7.5% 5% 7.5%;
  }

  .twoImages div:nth-child(1), .twoImages div:nth-child(2) {
    width: 100%;
  }

  .announcement-container {
    height: max-content;
    padding: 2.5% 0 10% 0;
  }

  #current-announcements  #participate{
    font-size: 2.5em;
  }

  #current-announcements  span{
    font-size: 1.8em;
  }

  .announcement-container img {
    width: 80%;
    height: auto;
  }

  .form {
    width: 90%;
  }

  .contact {
    width: 100%;
  }

  .contact-items {
    width: 100%;
  }

  .contact-items a, .design-and-code a {
    padding: 0;
    width: 2.8rem;
    margin: 10px 10px 0 10px;
  }

  .contact-items a img, .design-and-code a img {
    width: 100%;
  }

  .contact-item {
    width: 100%;
  }

  .contact .subtitle{
    font-size: 1.3rem;
    text-align: center;
  }

  .contact-icon {
    width: 2.8rem;
  } 

  .design-and-code {
    padding-bottom: 10px;
    justify-content: center;
    font-size: 1.3rem;
  }

  .email-phone-container {
    flex-direction: column;
  }

  .email-phone {
    flex-direction: row;
    justify-content: center;
  }

  .email-phone img {
    margin-right: 10px;
  }

  .copyright {
    font-size: 1.3rem;
  }

  span {
    font-size: 1rem;
  }

  footer {
    padding: 1% 2% 5vh 2%;
  }

  .footer-logo {
    width: 6rem;
  }

  .social {
    width: 20%;
  }

  .parallax {
    min-height: 30vh;
  }
}